export const textColors = {
  textDark: '#363636',
  textMedium: '#272e30',
  textLight: '#fff',
  textGray: '#a2a2a2',
  textGrayDark: '#6b6b6b',
  fail: '#e12a3c',
  pass: '#28cf87',
  codeHeader: '#5d5d5d'
};

export const borderColor = {
  dark: '#8f8f8f',
  medium: '#d4d4d4',
  light: '#e4e4e4',
  codeHeader: '#858585'
};

export const backgroundColor = {
  light: '#fff',
  almostLight: '#F8F8F8',
  mediumLight: '#f2f2f2',
  cardBackground: '#ececec',
  actionBackground: '#dfdfdf',
  headerBackground: '#d8d8d8',
  button: '#cfcfcf',
  headerBackgroundLight: '#ececec',
  codeHeader: '#eff6f9',
  transparent: 'rgba(155, 155, 150, 0.3)',
  selectedOption: 'rgba(154, 86, 159, 0.11)',
  success: '#4CC589',
  dark: '#272e30',
  unselectedButton: '#a2a2a2',
  scrollBar: '#AcAcAc',
  warning: '#ffcc00'
};

export const shadow = {
  default: '0 0.1rem 1.9rem 0 rgba(0, 0, 0, 0.09)',
  dark: '0 0.3rem 1.3rem 0 rgba(39, 46, 48, 0.12)',
  leftShadow: '-1rem 0.1rem 4rem 0.1rem #AAA;'
};

export const accentColor = {
  main: '#e12a3c',
  mainLight: '#f56F7F',
  sub: '#9a569f',
  subLight: '#CEADEF'
};
