export const rootConfig = {
  apiRoot: 'https://hiringapi.panorbitprojects.com/api'
};

export const apiEndPoints = {
  loginAPI: `${rootConfig.apiRoot}/login/`,
  quizDetailAPI: `${rootConfig.apiRoot}/QuizDetails/`,
  startQuizAPI: `${rootConfig.apiRoot}/StartQuiz/`,
  questionListAPI: `${rootConfig.apiRoot}/QuestionList/`,
  submitAnswerAPI: `${rootConfig.apiRoot}/SubmitQuiz/`,
  validateAnswerAPI: `${rootConfig.apiRoot}/ValidateAnswer/`
};

// loginAPI -> token and user credentials
// set token to webservice
// get quizList ( quizId )
// click start -> startQuizAPI
// submit individual answer -> submitAPI
// submit quiz -> validateAnswer
